// extracted by mini-css-extract-plugin
export var containerWrapper = "about-module--containerWrapper--MEJjD";
export var downloadResumeButton = "about-module--downloadResumeButton--oyt9i";
export var flex = "about-module--flex--tdmRM";
export var grid = "about-module--grid--kPPse";
export var mainContainer = "about-module--mainContainer--MR9dx";
export var photo = "about-module--photo--wu6ME";
export var resumeButtonText = "about-module--resumeButtonText--NOwio";
export var sectionWrapper = "about-module--sectionWrapper--JvtJD";
export var text = "about-module--text--kuyDm";
export var title = "about-module--title--NpBCD";